.ais-Pagination,
.ais-SearchBox {
  color: #21243d;
  font-size: 0.75rem;
}

.ais-Pagination-list {
  justify-content: center;
  display: -webkit-box;
  padding-inline-start: 0;
}

.ais-Pagination-item,
.ais-Pagination-link {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ais-Pagination-item {
  height: 38px;
  width: 38px;
}

.ais-Pagination-item {
  background-color: rgba(65, 66, 71, 0.08);
  border-radius: 4px;
  color: #414247;
}

.ais-Pagination-item--selected {
  background-color: #e2a400;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.34),
    rgba(255, 255, 255, 0)
  );
  font-weight: bold;
}

.ais-Pagination-item--firstPage,
.ais-Pagination-item--previousPage,
.ais-Pagination-item--nextPage,
.ais-Pagination-item--lastPage {
  background: none;
}

.ais-Pagination-item--disabled {
  opacity: 0.33;
}

.ais-Pagination-item--selected a {
  color: #fff;
}

.ais-Pagination-item.ais-Pagination-item--page {
  margin-right: 4px;
}

.ais-Pagination-item.ais-Pagination-item--previousPage {
  margin-right: 1rem;
}

.ais-Pagination-item.ais-Pagination-item--nextPage {
  margin-left: calc(1rem - 4px);
}

.ais-Pagination-link {
  height: 100%;
  width: 100%;
}

.ais-Pagination--noRefinement {
  display: none;
}

/* Search box */

.header .ais-SearchBox {
  height: 64px;
  width: 740px;
}

.header .ais-SearchBox .ais-SearchBox-input {
  
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.2);
  font-family: Hind, sans-serif;
  height: 64px;
  /*
        The "Hind" font family is vertically off-balance.
        Adding 4px of padding top makes it more vertically aligned.
      */
  padding: 4px 48px 0 64px;
}

.header .ais-SearchBox-submit {
  padding: 0 1rem 0 2rem;
  width: 64px;
}

.header .ais-SearchBox .ais-SearchBox-input::placeholder {
  color: rgba(33, 36, 61, 0.24);
  opacity: 1; /* Firefox */
}

.ais-SearchBox-input:-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-input::-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-submit {
  color: #e2a400;
}

.ais-RefinementList .ais-SearchBox-input {
  font-family: Hind, sans-serif;
  /*
        The "Hind" font family is vertically off-balance.
        Adding some padding top makes it more vertically aligned.
      */
  padding-top: 2px;
}

/* Search box */

.ais-SearchBox-form {
  position: relative;
}

.ais-SearchBox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(65, 66, 71, 0.06);
  border: none;
  border-radius: 3px;
  color: rgba(33, 36, 61, 0.8);
  font-family: inherit;
  min-height: 54px;
  outline: none;
  padding-left: 56px;
  width: 100%;
}

.ais-SearchBox-input::placeholder {
  color: rgba(33, 36, 61, 0.5);
  opacity: 1; /* Firefox */
}

.ais-SearchBox-input:-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.5);
}

.ais-SearchBox-input::-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.5);
}

.ais-SearchBox-reset,
.ais-SearchBox-loadingIndicator,
.ais-SearchBox-submit {
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
}

.ais-SearchBox-reset[hidden],
.ais-SearchBox-loadingIndicator[hidden] {
  display: none;
}

.ais-SearchBox-submit {
  left: 0;
}

.ais-SearchBox-reset,
.ais-SearchBox-loadingIndicator {
  right: 0;
}

.ais-SearchBox-resetIcon {
  height: 10px;
  width: 10px;
}

/* SFFV search box */

.ais-RefinementList .ais-SearchBox-input {
  border-radius: 3px;
  color: rgba(33, 36, 61, 0.8);
  font-size: 0.8rem;
  min-height: 40px;
  padding: 0 44px;
}

.ais-RefinementList .ais-SearchBox-form {
  margin-bottom: 1rem;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  background: rgba(226, 164, 0, 0.4);
}
